import React, { useEffect, useRef, useState, useMemo } from 'react';
import Link from './Link';
import Image from './Image';
// import gsap from 'gsap';
// import ScrollTrigger from 'gsap/ScrollTrigger';
import InViewMonitor from 'react-inview-monitor';
import Lottie from './Lottie';
import Activate from '../assets/animations/hp-activate.json';
import Predict from '../assets/animations/hp-predict.json';

import Connect from '../assets/animations/hp-connect.json';
// gsap.registerPlugin(ScrollTrigger);

const Animations = {
  activate: Activate,
  predict: Predict,
  connect: Connect,
};

const boxes = [
  ['#39AAFF', '#39AAFF', '#39AAFF', 'none', 'none', 'none', 'none', 'none', 'none'],
  ['none', 'none', 'none', 'none', '#7E5FFF', '#7E5FFF', 'none', 'none', '#7E5FFF'],
  ['none', 'none', 'none', '#29D5D5', 'none', 'none', '#29D5D5', '#29D5D5', 'none'],
];

export default function HomepageFeatures(props) {
  const wrapperRef = useRef();
  const featureRefs = [useRef(), useRef(), useRef()];
  const boxContainerRef = useRef();

  // useEffect(() => {
  //   const tween = gsap.from(boxContainerRef.current, {
  //     y: '300px',
  //     scrollTrigger: {
  //       trigger: wrapperRef.current, // start the animation when ".box" enters the viewport (once)
  //       scrub: true,
  //       start: 'top top', // when the top of the trigger hits the top of the viewport
  //       end: '+=500px',
  //       pin: false,
  //       // onUpdate(e) {
  //       //   console.log(e);
  //       // },
  //       // endTrigger: lastRef.current.previousElementSibling.querySelector('.text-card'),
  //       // pinReparent: false,
  //       // pinSpacing: false,
  //     },
  //   });

  //   return () => {
  //     tween.kill();
  //   };
  // });

  return (
    <section ref={wrapperRef} className="relative">
      <div ref={boxContainerRef} className="hidden md:block absolute inset-0 z-20 h-full w-full pointer-events-none">
        {boxes.map((box, i) => (
          <div key={`feature-aside-${i}`} className="sticky top-0 pointer-events-none" style={{ height: '33%' }}>
            <div className="container h-full pt-38 xl:pt-40" style={{ paddingLeft: '0', paddingRight: '0' }}>
              <div style={{ width: '42px' }} className="ml-1">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.655 36.655">
                  <path fill={box[0]} d="M0 0H10.428V10.428H0z"></path>
                  <path fill={box[3]} d="M0 13.114H10.428V23.542H0z"></path>
                  <path fill={box[6]} d="M0 26.227H10.428V36.655H0z"></path>

                  <path fill={box[1]} d="M13.114 0H23.542V10.428H13.114z"></path>
                  <path fill={box[4]} d="M13.114 13.114H23.542V23.542H13.114z"></path>
                  <path fill={box[7]} d="M13.114 26.227H23.542V36.655H13.114z"></path>

                  <path fill={box[2]} d="M26.227 0H36.655V10.428H26.227z"></path>
                  <path fill={box[5]} d="M26.227 13.114H36.655V23.542H26.227z"></path>
                  <path fill={box[8]} d="M26.227 26.227H36.655V36.655H26.227z"></path>
                </svg>
                {/* <div className="flex flex-wrap">
                  {box.map((b, index) => (
                    <div key={`feature${i}box${index}`} className={`w-1/3 bg-${b} pointer-events-none`} style={{ height: '14px', width: '14px', flex: '0 0 14px' }}></div>
                  ))}
                </div> */}
              </div>
            </div>
          </div>
        ))}
      </div>

      {props.features.map((feature, i) => (
        <InViewMonitor key={feature.textCard.heading + i} childPropsNotInView={{ isStopped: true }} childPropsInView={{ isStopped: false }} repeatOnInView={false} toggleChildPropsOnInView={true}>
          <Feature feature={feature} ref={featureRefs[i]} i={i} />
        </InViewMonitor>
      ))}
    </section>
  );
}
const Feature = React.forwardRef(({ i, feature, isStopped }, ref) => {
  const [shown, setShown] = useState(false);
  useEffect(() => {
    if (isStopped === false) {
      setShown(true);
    }
  }, [isStopped]);

  return (
    <div className="relative flex">
      <div ref={ref} className={`flex-grow w-full ${i % 2 !== 0 ? 'bg-tertiary' : 'bg-white'}`}>
        <div className="pad-tb md:pad-tb-none">
          <div className="container">
            <div className="flex flex-wrap items-center -ml-8 flex-auto">
              <div className="md:w-1/2 pl-8">
                <div className="text-card md:max-w-sm md:ml-10 text-center md:text-left md:pad-tb--xs lg:pad-tb xl:pad-tb--xl">
                  <h3 className="text-card__heading text-58px">{feature.textCard.heading}</h3>
                  <div className="text-card__content" dangerouslySetInnerHTML={{ __html: feature.textCard.content }} />
                  {feature?.textCard?.button?.url?.length > 0 ? (
                    <Link to={feature.textCard.button.url} className="text-card__button text-link text-link--black">
                      {feature.textCard.button.title}
                    </Link>
                  ): null}
                </div>
              </div>
              <div className="md:w-1/2 w-full mt-12 md:mt-0 pl-8">
                <div className="mx-auto w-full" style={{ maxWidth: '95%' }}>
                  <Lottie data={Animations[feature.animationName]} isStopped={!shown && isStopped} options={{ loop: false }} className="block" />
                  {feature.image && <Image data={feature.image} className="block mt-12 mb-12" />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
