import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Link from '../components/Link';
import LogoSection from '../components/LogoSection';
// import Image from '../components/Image';
import HomepageFeatures from '../components/HomepageFeatures';
import Funnel from '../components/Funnel';
import Testimonial from '../components/Testimonial';
import SEO from '../components/SEO';
import Lottie from '../components/Lottie';
import HeroLeft from '../assets/animations/hero-left.json';
import HeroRight from '../assets/animations/hero-right.json';
import HeroMobile from '../assets/animations/hero-mobile.json';
import parseHeading from '../utils/parseHeading';
import styled from 'styled-components';
import tw from 'twin.macro';
import withSizes from 'react-sizes';
import InViewMonitor from 'react-inview-monitor';

const Hero = styled.section`
  ${tw``}
  .text-col {
    @media screen and (min-width: 530px) {
      min-width: 530px;
    }
  }
`;

const Index = (props) => {
  const { isMobile } = props;
  const { seo } = props.data.wpPage;
  const { homeHero, homeCustomers, features, funnel, testimonial, latestContent } = props.data.wpPage.homePage;

  return (
    <Layout>
      <SEO seo={seo} />

      {/* Hero */}
      <Hero className="hero flex flex-col py-12 lg:py-20 max-w-screen overflow-x-hidden">
        <div className="container flex-auto flex justify-center">
          <div className="w-auto lg:w-3/12 relative">
            <div className="hidden absolute inset-0 ml-5 md:flex left-auto lg:left-0 flex-col justify-center" style={{ width: '220px' }}>
              {!isMobile && (
                <InViewMonitor key={`hero-left-monitor`} childPropsNotInView={{ isStopped: true }} childPropsInView={{ isStopped: false }} repeatOnInView={true} toggleChildPropsOnInView={true}>
                  <Lottie data={HeroLeft} options={{ loop: false }} />
                </InViewMonitor>
              )}
            </div>
          </div>
          <div className="text-col lg:w-6/12 flex flex-col justify-center items-center" style={{ zIndex: '1' }}>
            <div className="text-card mx-auto text-center mb-4 sm:mb-8 md:py-10 pt-8 sm:pb-8">
              <h1
                className="text-card__heading text-hero"
                dangerouslySetInnerHTML={{
                  __html: parseHeading(homeHero.heading, 'hero-heading-span-text text-purple'),
                }}
              />
              {isMobile && (
                <div className="container -mb-8 sm:hidden" style={{ maxWidth: '400px' }}>
                  <div className="o-ratio o-ratio--homepage-hero-mobile">
                    <div className="o-ratio__content">
                      <InViewMonitor intoViewMargin="0%" key={`hero-mobile-monitor`} childPropsNotInView={{ isStopped: true }} childPropsInView={{ isStopped: false }} repeatOnInView={true} toggleChildPropsOnInView={true}>
                        <Lottie data={HeroMobile} options={{ loop: false }} />
                      </InViewMonitor>
                    </div>
                  </div>
                </div>
              )}
              <p className="text-card__content text-22px mt-10">{homeHero.content}</p>
              <Link to={homeHero.button.url} className="mt-4 sm:mt-0 button button--h57 button--waves">
                {homeHero.button.title}
              </Link>
            </div>
          </div>
          <div className="hidden sm:block w-auto lg:w-3/12 relative">
            <div className="absolute inset-0 flex flex-col justify-center z-0 mx-auto  mb-5" style={{ width: '160px' }}>
              {!isMobile && (
                <InViewMonitor key={`hero-right-monitor`} childPropsNotInView={{ isStopped: true }} childPropsInView={{ isStopped: false }} repeatOnInView={true} toggleChildPropsOnInView={true}>
                  <Lottie data={HeroRight} options={{ loop: false }} />
                </InViewMonitor>
              )}
            </div>
          </div>
        </div>
      </Hero>

      <LogoSection data={homeCustomers} />

      <HomepageFeatures features={features} />

      <Funnel data={funnel} />

      <Testimonial data={testimonial} />
    </Layout>
  );
};

export const query = graphql`
  query Home {
    wpPage(slug: { eq: "home" }) {
      id
      title
      slug
      ...SEO
      homePage {
        homeCustomers {
          heading
          button {
            title
            url
          }
          logos {
            logo {
              ...Image
            }
          }
        }
        features {
          animationName
          image {
            ...Image
          }
          textCard {
            button {
              target
              title
              url
            }
            content
            heading
          }
        }
        funnel {
          boxes {
            textCard {
              button {
                title
                url
              }
              content
              heading
            }
            icon {
              ...Image
            }
          }
          heading
        }
        homeHero {
          heading
          content
          button {
            title
            url
          }
          # graphicLeft {
          #   ...Image
          # }
          # graphicRight {
          #   ...Image
          # }
        }
        latestContent {
          customerStory {
            cite {
              name
              role
            }
            image {
              ...Image
            }
            subheading
            textCard {
              button {
                target
                title
                url
              }
              content
              heading
            }
          }
          twoBlogPosts {
            post {
              __typename
              ... on WpPost {
                id
              }
            }
          }
        }
        testimonial {
          backgroundColour
          cite {
            name
            role
          }
          logo {
            ...Image
          }
          quote
        }
      }
    }
  }
`;

export default withSizes(({ width }) => ({
  isMobile: width && width < 640,
}))(Index);
