import React, { Component } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import tw from 'twin.macro';
import FunnelGraphic from '../assets/images/funnel-graphic.inline.svg';
import FunnelGraphicMobile from '../assets/images/funnel-graphic-mobile.inline.svg';
import Image from './Image';
import media from '../utils/media';

const FunnelSection = styled.div`
  @keyframes poly-1 {
    0% {
      opacity: 0.6;
      transform: scale(0.2);
    }
    100% {
      opacity: 0;
      transform: scale(1.4);
    }
  }

  @keyframes poly-2 {
    0% {
      opacity: 0.6;
      transform: scale(0.2);
    }
    100% {
      opacity: 0;
      transform: scale(1.5);
    }
  }

  .poly-1 {
    animation-name: poly-1;
    animation-iteration-count: infinite;
    animation-duration: 1.15s;
    transform-origin: center;
  }

  .poly-2 {
    transform-origin: center;
    animation-name: poly-2;
    animation-iteration-count: infinite;
    animation-duration: 1.15s;
    animation-delay: 0.3s;
  }
`;

const Box = styled.div`
  ${tw`rounded flex flex-col`}
  background-color:#242424;
  margin-top: -15px;

  > .relative {
    padding: 15px;
    ${tw`flex-auto flex flex-col`}
  }

  .box-line {
    height: 8px;
    width: 100%;
    border-radius: 4px;

    position:relative;
    &:before {
      content:'';
      position:absolute;
      bottom:100%;
      left:50%;
      transform:translateX(-50%);
      height:35px;
      width:10px;
      ${media.greaterThan('md')`
      display:none;
      `}
    }
  }

  .box-text {
    ${tw`flex-auto flex flex-col justify-between items-start`}
    padding: 35px 5px 20px 5px;
    ${media.greaterThan('lg')`
      padding:47px 15px 36px 15px;
    `}
  }

  &:nth-of-type(1) {
    .box-line {
      background-color: var(--l-blue);

      &:before {
        background-image: linear-gradient(0deg, var(--l-blue) 0%, #473883 50%);
        height:100px;
      }
    }
  }
  &:nth-of-type(2) {
    z-index:3;
    .box-line {
      background-color: var(--teal);
      &:before {
        background-image: linear-gradient(0deg,var(--teal) 40%,var(--l-blue) 90%);
      }
    }
    .box-icon {
      ${tw`absolute`}
      top:50%;

      &--left {
        left: -9px;
        transform: translate(-50%, -50%);
      }
      &--right {
        right: -9px;
        transform: translate(50%, -50%);
      }
      ${media.lessThan('md')`
        ${tw`hidden`}
      `}
      ${media.lessThan('lg')`
        max-width:40px;
      `}
      ${media.lessThan('xl')`
        max-width:50px;
      `}
    }
  }
  &:nth-of-type(3) {
    z-index:2;
    .box-line {
      background-color: var(--accent);
      &:before {
        background-image: linear-gradient(0deg, var(--accent) 40%, var(--teal) 90%);
      }
    }
  }
`;

export default class Volume extends Component {
  render() {
    const { heading, boxes } = this.props.data;
    return (
      <FunnelSection className="pad-tb--lg bg-black text-white overflow-hidden">
        <div className="container">
          <h2 className="text-58px text-center relative z-10" style={{ lineHeight: '1.23' }} dangerouslySetInnerHTML={{ __html: heading }} />

          <FunnelGraphic style={{ maxWidth: '76%' }} className="hidden md:block mx-auto overflow-visible w-full relative pointer-events-none" />
          <FunnelGraphicMobile style={{ maxWidth: '76%', zIndex: '11' }} className="md:hidden mx-auto overflow-visible w-full relative pointer-events-none" />

          <div className="grid md:grid-cols-3 col-gap-4 row-gap-8 relative z-10">
            {boxes.map(({ textCard, icon }, i) => (
              <Box key={`funnel-box-${i}`}>
                <div className="relative">
                  {i === 1 && <Icon className="box-icon box-icon--left" />}
                  {i === 1 && <Icon className="box-icon box-icon--right" />}

                  <div className="box-line"></div>
                  <div className="box-text">
                    <div>
                      <div className="flex items-center mb-4">
                        <Image data={icon} style={{ marginRight: '20px', width: '24px' }} />
                        <h4 className="text-24px font-semibold">{textCard.heading}</h4>
                      </div>
                      <div className="text-16px mb-8" dangerouslySetInnerHTML={{ __html: textCard.content }} />
                    </div>
                    {textCard?.button?.url?.length > 0 ? (
                      <Link to={textCard.button.url} className="text-link text-link--white">
                        {textCard.button.title}
                      </Link>
                    ): null}
                  </div>
                </div>
              </Box>
            ))}
          </div>
        </div>
      </FunnelSection>
    );
  }
}

function Icon(props) {
  return (
    <svg height="63" viewBox="0 0 62 63" width="62" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd" transform="translate(0 .206)">
        <circle cx="31" cy="31" fill="#7e5fff" opacity=".135393" r="31" />
        <circle cx="31" cy="31" fill="#7e5fff" r="17.442801" />
        <path d="m32.536 36.582v-4.632h4.224v-2.736h-4.224v-4.632h-2.952v4.632h-4.344v2.736h4.344v4.632z" fill="#fff" fillRule="nonzero" />
      </g>
    </svg>
  );
}
