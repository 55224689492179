import React, { Component } from 'react';
import Link from './Link';
import Image from './Image';

class LogoSection extends Component {
  render() {
    const { heading, button, logos } = this.props.data;
    const { cols = 4 } = this.props;

    return (
      <section className="logo-section pad-tb--sm bg-d-black text-white text-center">
        <div className="container">
          <h3 className="text-24px font-semibold mb-10">{heading}</h3>

          <div className={`grid md:grid-cols-${cols} grid-cols-2 text-center row-gap-3 md:row-gap-4 mx-auto ${cols <= 3 ? 'max-w-4xl' : ''}`}>
            {logos.map(({ logo }) => (
              <Image key={logo.id} data={logo} className="mx-auto" />
            ))}
          </div>

          {button?.url.length && (
            <Link to={button.url} className="text-link text-link--white mt-12">
              {button.title}
            </Link>
          )}
        </div>
      </section>
    );
  }
}

export default LogoSection;
